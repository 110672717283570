import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-25239e1a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-map"
};
var _hoisted_2 = {
  "class": "exit"
};
var _hoisted_3 = {
  style: {
    "padding-left": "1rem",
    "display": "inline-flex",
    "align-items": "center",
    "height": "3rem"
  }
};
var _hoisted_4 = {
  key: 2,
  "class": "message",
  style: {
    "padding-left": "1rem"
  }
};
var _hoisted_5 = {
  key: 3,
  "class": "message"
};
var _hoisted_6 = {
  key: 4,
  "class": "message"
};
var _hoisted_7 = {
  key: 5,
  "class": "message"
};
var _hoisted_8 = {
  key: 6,
  "class": "message"
};
var _hoisted_9 = {
  key: 7,
  "class": "message"
};
var _hoisted_10 = {
  "class": "navigation"
};
var _hoisted_11 = {
  key: 0,
  "class": "drone-button-flash"
};
var _hoisted_12 = {
  key: 1
};
var _hoisted_13 = {
  "class": "player"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tooltip, {
    placement: "right",
    content: "退出"
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        onClick: $setup.handleExit,
        name: "arrow-left"
      })];
    }),
    _: 1 /* STABLE */
  })]), _createVNode($setup["CesiumMap"], {
    "class": "map",
    ref: "bigemapRef",
    navigation: ""
  }, {
    nav: _withCtx(function () {
      var _a;
      return [_createElementVNode("div", _hoisted_3, [$setup.selectedDrone ? (_openBlock(), _createBlock($setup["DroneHmsItem2"], {
        key: 0,
        type: "dock",
        drone: $setup.selectedDrone
      }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createBlock($setup["DroneHmsItem2"], {
        key: 1,
        type: "aircraft",
        drone: $setup.selectedDrone,
        style: {
          "padding-left": "1rem"
        }
      }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_4, "[" + _toDisplayString((_a = $setup.selectedDrone) === null || _a === void 0 ? void 0 : _a.droneName) + "]", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.droneLat || 0) + "° N", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($setup.droneLng || 0) + "° E", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_7, "航高:" + _toDisplayString($setup.elevation || 0) + " m", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_8, "距离:" + _toDisplayString($setup.homeDistance || 0) + " m", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.selectedDrone ? (_openBlock(), _createElementBlock("div", _hoisted_9, "电量:" + _toDisplayString($setup.percentage || 0) + " %", 1 /* TEXT */)) : _createCommentVNode("v-if", true), $setup.isAircraftReturnAble ? (_openBlock(), _createBlock($setup["DeepButton"], {
        key: 8,
        "class": "label",
        style: {
          "margin-left": "1rem"
        },
        icon: "arrow-go-back",
        onClick: $setup.handleReturnHome,
        type: "danger",
        size: "small"
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("返航 ")];
        }),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)])];
    }),
    scrim: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.drones, function (item) {
        var _a;
        return _openBlock(), _createBlock($setup["DeepButton"], {
          "class": _normalizeClass($setup.droneExecuteJobTypeSave[item.gatewaySerialNumber] ? 'drone-button-working' : ((_a = $setup.selectedDrone) === null || _a === void 0 ? void 0 : _a.gatewaySerialNumber) === item.gatewaySerialNumber ? 'drone-button-selected' : 'drone-button'),
          onClick: function onClick($event) {
            return $setup.handleDroneSelect(item);
          },
          key: item.droneId
        }, {
          "default": _withCtx(function () {
            var _a;
            return [$setup.droneExecuteJobTypeSave[item.gatewaySerialNumber] ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.droneName) + "(任务中) ", 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(((_a = $setup.nearestDrone) === null || _a === void 0 ? void 0 : _a.gatewaySerialNumber) === item.gatewaySerialNumber ? item.droneName + '(距离最近)' : item.droneName), 1 /* TEXT */))];
          }),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "onClick"]);
      }), 128 /* KEYED_FRAGMENT */))]), _createElementVNode("div", _hoisted_13, [$setup.selectedDrone ? (_openBlock(), _createBlock($setup["AircraftPlayer"], {
        key: 0,
        aspect: "16:9",
        drone: $setup.selectedDrone
      }, null, 8 /* PROPS */, ["drone"])) : _createCommentVNode("v-if", true)])];
    }),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)]);
}