import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-fffbccd6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "flight-control-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["AircraftControlPlayer"], {
    drone: $setup.currentDrone,
    aspect: "16:9",
    style: {
      "flex": "1"
    }
  }, null, 8 /* PROPS */, ["drone"]), _createVNode($setup["MonitorComponent"])]);
}