import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-8b005fb0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "aircraft-camera-take-component"
};
var _hoisted_2 = {
  "class": "container"
};
var _hoisted_3 = {
  "class": "switch"
};
var _hoisted_4 = {
  "class": "desc"
};
var _hoisted_5 = {
  "class": "recording"
};
var _hoisted_6 = {
  key: 0,
  "class": "point"
};
var _hoisted_7 = {
  key: 1,
  "class": "duration"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.isAircraftCameraUseAble && ($setup.takePhotoMode || $setup.recordingMode) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepSvgIcon"], {
    name: $setup.takePhotoMode ? 'svg-photo-take' : 'svg-video-take',
    onClick: $setup.handleCheck
  }, null, 8 /* PROPS */, ["name"]), _createElementVNode("div", _hoisted_4, _toDisplayString($setup.takePhotoMode ? '拍照模式' : '录像模式'), 1 /* TEXT */)]), _createVNode($setup["DeepSvgIcon"], {
    name: $setup.takePhotoMode ? 'svg-photo-take-button' : $setup.recordingState ? 'svg-video-take-button' : 'svg-video-take-button2',
    "class": "button",
    onClick: $setup.handleCameraAction
  }, null, 8 /* PROPS */, ["name"])]), _createElementVNode("div", _hoisted_5, [$setup.recordingState ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("v-if", true), $setup.recordingState ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($setup.recordTimeCounter) + "秒", 1 /* TEXT */)) : _createCommentVNode("v-if", true)]), _createCommentVNode("        <DeepDynamicsText class=\"bottom\">{{cameraMode===0?remainPhotoNum+'张':remainRecordDuration+'s'}}</DeepDynamicsText>")])) : _createCommentVNode("v-if", true);
}