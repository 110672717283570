import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-40326ad2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "flight-control"
};
var _hoisted_2 = {
  "class": "container",
  ref: "containerRef"
};
var _hoisted_3 = {
  "class": "divider"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["FlightControlHeader"]), _createElementVNode("div", _hoisted_2, [_createVNode($setup["FlightControlLeft"]), _createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepHammerView"], {
    type: ['pin'],
    onPan: $setup.handleDrag
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", {
        "class": _normalizeClass(["drag", $setup.dragStart ? 'dragging' : ''])
      }, null, 2 /* CLASS */)];
    }),
    _: 1 /* STABLE */
  })]), _createVNode($setup["FlightControlRight"], {
    style: _normalizeStyle({
      width: $setup.currentWidth
    })
  }, null, 8 /* PROPS */, ["style"]), $setup.FlightControlModule.controlService.controlType === 2 && $setup.drcStateConnected ? (_openBlock(), _createBlock($setup["JoyStickLayout"], {
    key: 0,
    onOnJoyStickUpdate: $setup.handleMyJoyStickUpdate
  })) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */)]);
}